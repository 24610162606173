<template>
  <v-app :class="{ 'login-body': isLogin }">
    <div id="app">
      <v-app-bar
        class="sch-app-bar"
        fixed
        v-if="
          !isLogin &&
          !$route.path.includes('print-preview') &&
          !$route.path.includes('generate-qr-code')
        "
      >
        <v-toolbar-title class="v-logo-container">
          <img src="./assets/tamilini-font.png" height="50" />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-web</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="setLanguage(item.key)"
            >
              <v-list-item-action>
                <v-icon v-if="selectedLanguage == item.key">mdi-check</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle color="grey">{{
                item.title
              }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y class="sch-profile-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-avatar size="36px">
                <v-img
                  :src="filePath + 'user_attachments/' + photo_path"
                  v-if="photo_path != ''"
                ></v-img>
                <v-img
                  :src="filePath + 'user_attachments/sample-image.png'"
                  v-else
                ></v-img>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ this.userDetails.first_name }}
                    {{ this.userDetails.last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    this.userDetails.email
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list nav dense>
            <v-list-item-group color="primary">
              <v-list-item @click="logOut()">
                <v-list-item-icon>
                  <v-icon>mdi-power</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-main>
        <div class="main-content">
          <router-view />
          <v-snackbar
            v-model="snackbar"
            :timeout="snackbarTime"
            :color="snackbarColor"
          >
            <div class="text-center">{{ snackBarMessage }}</div>
          </v-snackbar>
        </div>
      </v-main>
    </div>
    <v-bottom-navigation
      v-model="value"
      fixed
      v-if="
        (!isLogin &&
          !$route.path.includes('print-preview') &&
          !$route.path.includes('generate-qr-code') &&
          $route.path.includes('plannerApp')) ||
        this.user == 'SUP'
      "
    >
      <template v-for="(list, index) in supList">
        <v-btn
          color="#eaeaea"
          style="margin-top: 0.5rem"
          :value="list.route"
          @click="clickNavBar(list)"
          :key="index"
          v-if="list.perimission.includes(user)"
        >
          <span>{{ list.name }}</span>
          <v-icon :color="$route.name == list.route ? 'primary' : ''">{{
            list.icon
          }}</v-icon>
        </v-btn>
      </template>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import bottomNavList from "@/utils/bottomNavList";
import Utils from "../src/utils/utils";
export default {
  data: () => ({
    value: "",
    photo_path: "",
    snackbarTime: "2000",
    snackbarColor: "black",
    selectedLanguage: "en",
    snackbar: false,
    snackBarMessage: " Success message",
    drawer: true,
    mini: false,
    group: null,
    isLogin: false,
    supList: bottomNavList,
    testName: "message.header",
    user: "",
    userDetails: {},
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
      },
      {
        text: "Dashboard 111",
        disabled: true,
      },
    ],
    items: [
      {
        title: "English",
        key: "en",
      },
      {
        title: "Malay",
        key: "my",
      },
      {
        title: "Tamil",
        key: "tn",
      },
    ],
  }),
  mounted() {
    if (this.$route.name) this.value = this.$route.name;
    else {
      let name = window.location.pathname;
      name = name.replace("/", "");
      this.value = name;
    }
    this.user = this.$utils.getUserType();
    this.userDetails = this.$utils.getUserDetails();
    this.$eventBus.$on("snackbar-message", this.showSnackBar);
    this.$eventBus.$on("set-user-type", this.setUserType);
    this.$eventBus.$on("set-user-photo", (path) => {
      this.photo_path = path;
    });
    this.selectedLanguage = localStorage.getItem("language") || "en";
    this.mini = true;
    if (this.user == "SUP") {
      this.items = this.items.filter((el) => el.key != "cn");
    }
    if (this.$utils.getUserInfo("id")) this.getSelectList();
    this.photo_path = this.$utils.getUserPhoto() || "";
  },
  beforeMount() {
    this.isLogin = window.location.pathname.includes("login");
  },
  watch: {
    group() {
      this.drawer = false;
    },
    $route(to) {
      this.isLogin = to.name === "login";
    },
  },
  methods: {
    clickNavBar(list) {
      if (this.$route.name != list.route) {
        this.$router.replace(list.route);
      }
    },
    getSelectList() {
      let getList = localStorage.getItem("listDetails") || "";
      if (getList == "") {
        this.$http.get(`${this.baseURL}schools/get-list`).then((res) => {
          for (const [key, value] of Object.entries(res.data)) {
            this[key] = value;
          }
          localStorage.setItem("listDetails", JSON.stringify(res.data));
        });
      }
    },
    setUserType() {
      if (this.$route.name) this.value = this.$route.name;
      else {
        let name = window.location.pathname;
        name = name.replace("/", "");
        this.value = name;
      }
      this.user = Utils.getUserType();
      this.userDetails = Utils.getUserDetails();
    },
    logOut() {
      localStorage.clear();
      setTimeout(() => {
        this.$router.push({ name: "login" });
      }, 1000);
    },
    showSnackBar(obj) {
      this.snackbar = obj.flag;
      this.snackBarMessage = obj.message;
      this.snackbarColor = obj.color || "black";
      this.snackbarTime = obj.timeout || "2000";
    },
    navigateLink(link) {
      this.$router.push({ name: link }).catch(() => {});
    },
    setLanguage(lang) {
      localStorage.setItem("language", lang);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.v-bottom-navigation {
  background-color: #eaeaea !important;
}
.v-tab {
  text-transform: none !important;
}
.v-btn {
  text-transform: none !important;
}
.v-toolbar__content {
  background-color: #1f3370;
}
body {
  text-transform: none !important;
}
.theme--light.v-application {
  background-color: #eaeaea;
  // background-image: url("./assets/bg-theme.png");
  &,
  .text-h6 {
    font-family: "Poppins", sans-serif !important;
  }

  &.login-body {
    background-color: #fff;
  }
}

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.elevation-10 {
  box-shadow: 1px 0 20px #00000014 !important;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: rgb(24, 118, 209);
  color: #fff;
}

.theme--light.v-btn.v-btn--icon {
  color: #fff;
}

.v-toolbar__title {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
}

.v-navigation-drawer {
  top: 64px !important;

  .theme--light {
    &.v-list {
      &.sch-menu-items {
        padding-left: 12px;
        padding-right: 12px;

        .v-list-item {
          padding: 4px 15px;
          border-radius: 8px;
          margin-bottom: 8px;
          margin-top: 4px;
        }

        .v-list-item__icon {
          margin-right: 0;
        }
      }
    }
  }

  .v-sheet {
    .v-list-item__title {
      margin-left: 10px;
      font-size: 0.95rem;
      font-weight: 400;
    }

    .v-list-item--active {
      background-color: rgb(12, 185, 197);
      color: #fff;
      font-weight: 500;
    }
  }
}

.theme--light {
  &.v-list {
    padding: 8px 0 0;

    &.sch-profile {
      background-image: url("./assets/profile_bg.jpg");

      .v-list-item--link:before {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 1;
      }

      .v-list-item {
        min-height: 40px;
      }

      .v-list-item__content {
        padding: 10px 0;
        position: relative;
        z-index: 1;

        &,
        .v-list-item__subtitle {
          color: #fff !important;
        }
      }

      .sch-profile-avatar {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
      }

      .v-list-item__title {
        font-size: 0.85rem;
        font-weight: 500;
      }
    }
  }

  &.v-app-bar.v-toolbar.v-sheet {
    z-index: 7;
  }
}

.v-main {
  padding: 10px 20px !important;
  margin-top: 36px;
  .main-content {
    margin-top: 30px;
  }

  .v-page-title.v-toolbar__title {
    h3 {
      text-transform: none;
      font-weight: 400;
      font-size: 1.65rem;
    }
  }

  &.login-container {
    padding: 0 !important;

    &,
    .main-content {
      margin: 0;
    }
  }
}

.v-breadcrumbs {
  margin-bottom: 10px;
  padding: 0 !important;
}

.v-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
    min-width: unset;
    min-height: 100%;
  }
}
</style>
